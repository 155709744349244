#home {
  position: relative;
  // background: url('../../assets/blob-scene-haikei.svg');
  background-color: #f4f1de;
  background-size: cover;
  background-repeat: repeat;
  background-position: center;

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 4rem 2rem 0;

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    padding-top: 10rem;
  }

  @media screen and (max-width: 900px) {
    // padding: 2rem 1rem;
    flex-direction: column-reverse;
    flex-wrap: flex;
    padding-top: 4rem;
  }
}

.app__header-intro {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 50%;
  margin-left: 2rem;
  padding-top: 16vh;

  @media screen and (max-width: 900px) {
    align-items: center;
    text-align: center;
    margin-left: 0;
    width: auto;
  }

  @media screen and (max-width: 1200px) {
    text-align: center;
    align-items: center;
    width: 100%;
    padding-top: 2.5rem;
    margin-left: 0;
  }

  h2 {
    font-size: 40px;
    margin: 1rem;
    color: #181818;

    @media screen and (max-width: 900px) {
      font-size: 33px;
      font-weight: 500;
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 100;
    margin: 1vh 1rem;
    line-height: 2.3rem;
    color: #2e2e2e;

    @media screen and (max-width: 1200px) {
      font-weight: 300;
      font-size: 21px;
    }
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  .app__header-cards {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin: 18px;

    .app__header-card-linkedin {
      min-width: 170px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 1;

      margin: 1rem;
      margin-left: 0;
      padding: 10px 42px;
      border-radius: 5px;
      cursor: pointer;
      background-color: #3d405b;
      color: #fffffe;

      @media screen and (max-width: 900px) {
        min-width: 195px;
        font-size: 17px;
        margin: 10px;
        text-align: center;
      }
    }
    .app__header-card-github {
      min-width: 170px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 1;

      margin: 1rem;
      padding: 10px 42px;
      border-radius: 5px;
      cursor: pointer;
      background-color: #fffffe;
      color: #181818;
      border: solid 1px #e3e3e3;

      @media screen and (max-width: 900px) {
        min-width: 195px;
        font-size: 17px;
        margin: 10px;
        text-align: center;
      }
    }

    @media screen and (max-width: 900px) {
      width: 90%;
      display: flex;
      margin: 0px;
    }
    @media screen and (max-width: 1200px) {
      justify-content: center;
    }
  }
}

.app__header-img {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex;
  border-radius: 30px;

  .profile-pic {
    width: 35vw;
    max-width: 500px;
    // height: 40rem;
    object-fit: contain;
    z-index: 1;
    border-radius: 30px;

    image-rendering: smooth;

    @media screen and (max-width: 900px) {
      flex: 1;
      width: 50%;
      max-width: 400px;
    }

    @media screen and (max-width: 1200px) {
      width: 80%;
    }
    @media screen and (min-width: 2000px) {
      max-width: 550px;
    }
    @media screen and (min-width: 2000px) {
      max-width: 550px;
    }
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
}
