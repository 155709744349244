.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 1rem;
  }
}

.work-head {
  color:#81b29a !important;

  span {
    color:#3d405b !important;
  }
}

.app__work-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 4rem 0 2rem;

  .app__work-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #e07a5f;
    color: #f4f1de !important;
    font-weight: 800;
    cursor: pointer;
    margin: 0.5rem;

    &:hover {
      color: #181818;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: #3d405b;
    color: #fff;
  }
}

.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__work-item {
    width: 270px;
    flex-direction: column;

    margin: 2rem 4rem;
    padding: 1rem;
    border-radius: 1.5rem;
    background-color: #f9f7e9;

    cursor: pointer;
    // transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }
    @media screen and (min-width: 2000px) {
      width: 400px;
      padding: 1.25rem ;
      border-radius: 35px;
    }

    @media screen and (max-width: 1200px) {
      width: 50%;
      margin: 1rem;
      height: auto;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      margin: 1rem;
      height: auto;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
}

.app__work-img {
  width: 100%;
  height: 230px;
  position: relative;

  img {
    width: 100%;
    height: 100%;

    border-radius: 0.5rem;
    object-fit: cover;

    image-rendering: auto;
  }
  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  border-radius: 0.5rem;
  opacity: 0;

  div {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #e07a5f;
    color: #f4f1de;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;

    svg {
      width: 50%;
      height: 50%;
      color: #f2eef5;
    }
  }

}

.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    color: #3d405b;
    margin-top: 1rem;
    line-height: 1.5;
  }

  .app__work-tag {
    position: absolute;

    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #f9f7e9;
    top: -25px;
  }
}

.work-text {
  color: #3d405b;
  font-size: 0.8rem;
  text-align: left;
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 0.8rem;
  }
}
