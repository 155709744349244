@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --font-base: 'DM Sans', sans-serif;

  --primary-color: #f4f1de;
  --secondary-color: #f2cc8f;
  --black-color: #030303;
  --lightGray-color: #eae2b7;
  --gray-color: #6b7688;
  --brown-color: #e07a5f;
  --white-color: #ffffff;
  --blue-color: #3d405b;
  --green-color: #81b29a;

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
