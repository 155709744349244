.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.about-head {
  color: #f2cc8f !important;

  span {
    color: #f4f1de !important;
  }
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 2rem 4rem;

  img {
    width: 100%;
    height: 270px;
    border-radius: 15px;
    object-fit: cover;

    @media screen and (max-width: 900px) {
      height: 180px;
    }
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      image-rendering: smooth;

      height: 320px;
    }
  }
  @media screen and (max-width: 900px) {
    width: 200px;
  }
}

.about-title {
  color: #f4f1de;
  font-size: 15px;
  font-weight: 800;
}

.p-text {
  font-size: 13px !important;
  font-weight: 300;
  color: #3d405b;
}

.fun-h5 {
  font-size: 20px;
  margin-left: 30rem;

  @media screen and (max-width: 900px) {
    margin-left: 11rem;
    font-size: 14px;
  }
}
