.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    height: 50rem;
  }
}

.app__skills-container {
  width: 100%;
  margin-top: 3rem;

  display: flex;
  flex-direction: column;

  align-items: center;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-top: 1rem;
  }
}

.app__skills-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    margin-right: 0;
    display: none;
  }
  @media screen and (max-width: 1200px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
}

.app__skills-item {
  flex-direction: row;
  text-align: center;
  z-index: 4;

  margin: 1rem;

  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #f4f1de !important;

    img {
      width: 3rem;
      height: 3rem;
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }
    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
    color: #3d405b;
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }

  
}

.app__skills-exp {
  // flex: 1;
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  margin-bottom: 5rem;

  @media screen and (max-width: 900px) {
    margin-top: -5rem;
  }
}

.app__skills-exp-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 7rem 0;
}

.app__skills-exp-works {
  flex: 1;

  .app__skills-exp-work {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    cursor: pointer;

    h4 {
      font-weight: 500;
      color: #fbdd74;
    }

    p {
      font-weight: 400;
      color: #4fc4cf;
      margin-top: 5px;
    }
  }
}

.app__skills-exp-year {
  margin-right: 3rem;

  p {
    font-weight: 800;
    color: #181818;
  }

  @media screen and (max-width: 450px) {
    margin-right: 1rem;
  }
}

.disclaimer-text {
  color: #3d405b;
  font-size: 11px;
  margin-left: 28rem;

  @media screen and (max-width: 900px) {
    margin-left: 10rem;
    font-size: 9px;
  }
}

.card {
  display: block;
  background: #81b29a;
  color: #f4f1de;
  padding: 2rem 5rem;
  text-align: center;
  max-width: 50rem;
  //   transition: height 1s linear;
  @media screen and (max-width: 900px) {
    padding: 2rem 4rem;
  }

  h2 {
    display: inline-block;

    @media screen and (max-width: 900px) {
      font-size: 18px;
    }
  }

  p {
    font-weight: 500 !important;
    font-size: 14px !important;
    padding-top: 1rem;
    line-height: 150%;
    color: #3d405b !important;

    @media screen and (max-width: 900px) {
      font-size: 13px;
      margin: -20px;
    }

    @media screen and (max-width: 1200px) {
      font-size: 13px;
      margin: -20px;
      padding-bottom: 1rem;
    }
  }
}
